"use strict";

exports.DEFAULT_OPTIONS = {
  maxWidth: 650,
  wrapperStyle: "",
  backgroundColor: "white",
  linkImagesToOriginal: true,
  showCaptions: false,
  markdownCaptions: false,
  withWebp: false,
  withAvif: false,
  tracedSVG: false,
  loading: "lazy",
  disableBgImageOnAlpha: false,
  disableBgImage: false
};
exports.EMPTY_ALT = "GATSBY_EMPTY_ALT";
exports.imageClass = "gatsby-resp-image-image";
exports.imageWrapperClass = "gatsby-resp-image-wrapper";
exports.imageBackgroundClass = "gatsby-resp-image-background-image";